import { createStore } from "vuex";

export default createStore({
  state: {
    hangupCall: "",
  },
  getters: {
    getHangupCall(state) {
      return state.hangupCall;
    },
  },
  mutations: {},
  actions: {
    TOGGLE_HANGUPCALL(state) {
      state.hangupCall = !state.hangupCall;
    },
  },
  modules: {},
});
