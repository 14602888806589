<template>
  <div>
    <v-card :color="statusColor">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div class="pa-2">
          <v-card-title class="text-h5">Alotel Webphone</v-card-title>
          <v-card-subtitle
            ><h3>{{ registerer.state }}</h3></v-card-subtitle
          >
          <v-card-actions>
            <v-btn
              class="ms-2"
              icon="mdi-refresh"
              variant="text"
              @click="forceRegistration()"
            ></v-btn>
            <audio src="" id="mediaElement" controls hidden></audio>
          </v-card-actions>
        </div>
        <div class="pa-2 webphone-icon">
          <v-icon size="100px" v-show="status === 'Registered'">
            mdi-check-circle</v-icon
          >
          <v-icon size="100px" v-show="!regStatuses.includes(status)">
            mdi-alert-circle</v-icon
          >
          <v-icon size="100px" v-show="status === 'CONNECTING'">
            mdi-dialpad</v-icon
          >
          <v-icon size="100px" v-show="status === 'RINGING'">
            mdi-bell-circle</v-icon
          >
          <v-icon size="100px" v-show="status === 'CONNECTED'">
            mdi-connection</v-icon
          >
          <v-icon size="100px" v-show="status === 'DISCONNECTED'">
            mdi-phone-hangup</v-icon
          >
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { Inviter, UserAgent, SessionState } from "sip.js";
import {
  initWebPhone,
  onInvite,
  initUA,
  registerUA,
  startRingTone,
  stopRingTone,
} from "@/plugins/webphone";

export default {
  name: "WebPhone",
  props: ["dialExten", "exten", "identity", "hangupCall"],
  setup() {},
  data: () => ({
    ua: null,
    idt: "",
    status: "",
    registerer: "",
    registered: false,
    statusColor: "blue",
    session: null,
    mediaElement: null,
    regStatuses: [
      "Registered",
      "CONNECTING",
      "RINGING",
      "CONNECTED",
      "DISCONNECTED",
    ],
    alertDialog: {
      display: false,
      text: "",
    },
  }),
  methods: {
    forceRegistration() {
      this.registerer = registerUA(this.ua);

      this.ua.start().then(() => {
        this.registerer.register({
          requestDelegate: {
            onAccept() {
              this.registered = true;
              console.log("--------------------------------");
              //console.log(this.registerer.state);
              this.statusColor = "blue";
            },
            onReject(response) {
              console.log(response);
              this.statusColor = "red";
            },
          },
        });
      });
    },

    initSession(session) {
      this.session = session;
      console.log(session);
    },

    setupRemoteMedia(session) {
      session.sessionDescriptionHandler.peerConnection
        .getReceivers()
        .forEach((receiver) => {
          if (receiver.track) {
            this.remoteStream.addTrack(receiver.track);
          }
        });
      this.mediaElement.srcObject = this.remoteStream;
      this.mediaElement.play();
    },

    cleanupMedia() {
      this.mediaElement.srcObject = null;
      this.mediaElement.pause();
    },

    dial(exten) {
      this.mediaElement = document.getElementById("mediaElement");
      this.remoteStream = new MediaStream();
      console.log("dialing: " + exten);
      this.target = UserAgent.makeURI(`sip:${exten}@maroc-integration.ma`);
      const inviter = new Inviter(this.ua, this.target);
      this.session = this.initSession(inviter);
      inviter.stateChange.addListener((state) => {
        console.log(`Session state changed to ${state}`);
        this.initSession(inviter);
        this.dialog = true;
        switch (state) {
          case SessionState.Initial:
            this.status = "CONNECTING";
            break;
          case SessionState.Establishing:
            this.incall = true;
            this.status = "RINGING";
            this.ring = startRingTone();
            break;
          case SessionState.Established:
            stopRingTone(this.ring);
            this.status = "CONNECTED";
            this.setupRemoteMedia(inviter);
            break;
          case SessionState.Terminating:
            // fall through
            this.hangup();
            break;
          case SessionState.Terminated:
            console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
            stopRingTone(this.ring);
            this.incall = false;
            this.dialClick = 0;
            this.status = "DISCONNECTED";
            this.cleanupMedia();
            this.$emit("hangup");
            break;
          default:
            throw new Error("Unknown session state.");
        }
      });
      inviter.invite();
    },

    hangup() {
      console.log("Emitting hangup event");
      if (this.session) {
        this.session.dispose();
      }

      this.$emit("hangup");
    },
  },
  mounted() {
    let exten;
    let identity;

    if (!this.$route.query.room || this.$route.query.room === "") {
      this.alertDialog.display = true;
      this.alertDialog.text = "Aucune extension n'a été fournie";
    } else {
      exten = this.$route.query.room;
      identity = this.$route.query.identity;
    }

    this.credentials = {
      aor: `${exten}@maroc-integration.ma`,
      password: `${exten}_2024`,
    };

    const userAgentOptions = initWebPhone(this.credentials, identity, onInvite);
    this.ua = initUA(userAgentOptions);
    this.registerer = registerUA(this.ua);
    this.forceRegistration();
  },
  watch: {
    statusColor: {
      immediate: true,
      handler(n) {
        this.statusColor = n;
      },
    },
    registerer: {
      immediate: true,
      handler(n) {
        console.log("--------------------------------");
        console.log(n);
        this.registerer = n;
        this.status = this.registerer.state;
        this.$emit("registration", n);
      },
      deep: true,
    },
    dialExten: {
      immediate: true,
      handler(n) {
        console.log("Receiving dial event for exten: " + n);
        if (n === true) {
          this.dial(this.exten);
        }
      },
      deep: true,
    },
    hangupCall: {
      immediate: true,
      handler(n) {
        console.log("Receiving hangup event: " + n);
        if (n === true) {
          this.hangup();
        }
      },
    },
    status: {
      immediate: true,
      handler(n) {
        console.log("Session status: " + n);
      },
    },
    identity: {
      immediate: true,
      handler(n) {
        this.idt = n;
      },
    },
  },
};
</script>

<style scoped>
.webphone-icon {
  width: 20%;
  position: absolute;
  right: 0;
  margin: 20px;
}
audio {
  opacity: 20%;
}

audio::-webkit-media-controls-mute-button {
  background-color: #b1d4e0;
  border-radius: 50%;
}
</style>
