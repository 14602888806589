<template>
  <div>
    <v-card
      elevation="16"
      :title="item.title"
      :subtitle="item.subtitle"
      :image="item.image"
      width="100%"
      dark
    >
      <v-card-text class="ma-2">
        <v-fade-transition leave-absolute>
          <v-btn
            class="ma-2"
            icon="mdi-phone"
            @click="dial(item, index)"
            :disabled="!registered"
            v-if="onCall === false"
            transition="fade-transition"
            flat
          ></v-btn>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-btn
            class="ma-2"
            icon="mdi-phone-off"
            color="red"
            @click="hangup()"
            v-if="onCall === true"
            transition="fade-transition"
            flat
          ></v-btn>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-btn
            class="ma-2"
            icon="mdi-volume-medium"
            @click="muteSpeaker()"
            v-if="onCall === true && speakerOff === false"
            transition="fade-transition"
            flat
          ></v-btn>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-btn
            class="ma-2"
            icon="mdi-volume-off"
            color="red"
            @click="unmuteSpeaker()"
            v-if="onCall === true && speakerOff === true"
            transition="fade-transition"
            flat
          ></v-btn>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-btn
            class="ma-2"
            icon="mdi-microphone"
            @click="mute()"
            v-if="onCall === true && muted === false"
            transition="fade-transition"
            flat
          ></v-btn>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-btn
            class="ma-2"
            icon="mdi-microphone-off"
            color="red"
            @click="unmute()"
            v-if="onCall === true && muted === true"
            transition="fade-transition"
            flat
          ></v-btn>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AlotelOption",
  props: ["item", "index", "registered", "hangupCall"],
  setup() {},
  data: () => ({
    onCall: false,
    muted: false,
    speakerOff: false,
    clickedItem: null,
    itemKey: null,
  }),
  methods: {
    dial(item, key) {
      this.itemKey = key;
      this.clickedItem = item;
      this.onCall = true;
      console.log("Emitting dial event ");
      this.$emit("dial", item.exten);
    },

    hangup() {
      console.log("Hanging up this call");
      this.onCall = false;
      this.speakerOff = false;
      this.muted = false;
      this.$emit("hangup");
    },

    muteSpeaker() {
      this.speakerOff = true;
    },

    unmuteSpeaker() {
      this.speakerOff = false;
    },

    mute() {
      this.muted = true;
    },

    unmute() {
      this.muted = false;
    },
  },
  computed: {
    getHangupCall() {
      return this.$store.getters.getHangupCall;
    },
  },
  watch: {
    hangupCall: {
      immediate: true,
      handler(n) {
        if (n === true) {
          console.log("Hanging up this call");
          this.onCall = false;
          this.speakerOff = false;
          this.muted = false;
        }
      },
    },
    onCall: {
      immediate: true,
      handler(n) {
        this.onCall = n;
        console.log(this.onCall);
      },
    },
  },
};
</script>
